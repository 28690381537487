import { Table } from "antd";
// import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import DeleteModal from "../../../components/modelpopup/DeleteModal";
import EmployeeLeaveModelPopup from "./EmployeeLeaveModelPopup";
import EmployeeWFHModelPopup from "./EmployeeWFHModelPopup";
import EmployeeVisitModelPopup from "./EmployeeVisitModelPopup";
import SearchBox from "../../../components/SearchBox";
import { getEmpData } from "../../Services/ApiCalling"
import moment from "moment"
import LeaveFilter from "./LeaveFilter";
import { tuple } from "yup";

export const EmployeeLeave = ({ empStatsData }) => {

  const date = new Date();
  let curr_date = date.toLocaleDateString();
  let first_date = new Date(date.getFullYear(), date.getMonth() - 1, 1);

  const [apptype, setApptype] = useState({ value: "LEAVE", label: "Leave" });
  const [appStatus, setAppStatus] = useState({ value: "PENDING", label: "Pending" });
  const [fromDate, setFromDate] = useState(first_date.toLocaleDateString());
  const [toDate, setToDate] = useState(curr_date);



  // {console.log("empStatsDataempStatsData",empStatsData)}
  const [fieldInputs, setFieldInputs] = useState(true);
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(null);
  const [pageSize, setPageSise] = useState(null);
  const [totalRecord, settotalRecord] = useState(null);
  const [totalPage, settotalPage] = useState(null);
  const [users, setUsers] = useState();


  const token = localStorage.getItem("LogIn_Token")

  const empData = async (token, apptype, appStatus, fromDate, toDate) => {
    setIsLoading(true)
    const resp = await getEmpData(token, apptype, appStatus, fromDate, toDate)
    setUsers(resp.data);
    console.log("paggination", resp.data);
    setCurrentPage(resp?.pagination?.currentPage);
    setPageSise(resp?.pagination?.pageSize);
    settotalPage(resp?.pagination?.totalPages);
    settotalRecord(resp?.pagination?.totalRecords);
    setIsLoading(false)
  }

  useEffect(() => {
    empData(token, apptype, appStatus, fromDate, toDate);
  }, [token, apptype, appStatus, fromDate, toDate]);

  const ApiCall = ()=> {
    try{
      empData( token, { value: "LEAVE", label: "Leave" }, { value: "PENDING", label: "Pending" }, fromDate, toDate)
    }
    catch (error){
      console.error('Error:', error);
    }
  }

  function mouseOver(event) {
    event.target.style.background = "#FF9F43";
    event.target.style.color = "white";
  }
  function mouseOut(event) {
    event.target.style.background = "white";
    event.target.style.color = "black";
  }

  const columns = [
    {
      title: "Request Type",
      dataIndex: "REQ_TYPE",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.REQ_TYPE.length - b.REQ_TYPE.length,
    },

    {
      title: "From",
      dataIndex: "REQ_FROM",
      render: (text) => <span>{moment(text).format('DD-MM-YYYY')}</span>,
      sorter: (a, b) => a.REQ_FROM.length - b.REQ_FROM.length,
    },
    {
      title: "To",
      dataIndex: "REQ_TO",
      render: (text) => <span>{moment(text).format('DD-MM-YYYY')}</span>,
      sorter: (a, b) => a.REQ_TO - b.REQ_TO,
    },

    {
      title: "No Of Days",
      dataIndex: "NO_DAYS",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.NO_DAYS.length - b.NO_DAYS.length,
    },

    {
      title: "Reason",
      dataIndex: "REASON",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.REASON.length - b.REASON.length,
    },
    {
      title: "Status",
      dataIndex: "REQ_STATUS",
      sorter: true,
      render: (text) => {
        let color;

        // Determine the color based on the status
        if (text === "PENDING") {
          color = "orange";
        } else if (text === "REJECT") {
          color = "red";
        } else {
          color = "green";
        }

        return <span style={{ color }}>{text}</span>;
      },

    },
    {
      title: "Remarks",
      dataIndex: "HEAD_REMARK",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.HEAD_REMARK.length - b.HEAD_REMARK.length,
    },

  ];


  const leaveStats = [
    {
      id: 1,
      title: "Total Leave",
      value: empStatsData.TOTAL_LEAVE,
    },
    {
      id: 2,
      title: "Pending Leave/Current Leave",
      value: empStatsData.pendingRequestsCount,
    },
    {
      id: 3,
      title: "Remaining Leave",
      value: empStatsData.REM_LEAVE,
    },
  ];


  ///////////////////////////SIMMER EFFECT CODE
  const renderPlaceholder = () => (
    <>
      <p className="placeholder-glow">
        <span className="placeholder col-12" />
      </p>
      <p className="placeholder-wave mb-0">
        <span className="placeholder col-12" />
      </p>
    </>
  );

  const placeholderData = new Array(5).fill({});
  ///////////////////////////SIMMER EFFECT CODE 

  return (
    <>
      <ToastContainer />
      <div className="page-wrapper">
        <div className="content container-fluid">

          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">{"Applications"}</h3>
              <ul className="breadcrumb">
                {/* <li className="breadcrumb-item">
                  <Link to="">{"Dashboard"}</Link>            
                </li> */}
                <li className="breadcrumb-item active">{"Leave, WFH, Official_Visit"}</li>
              </ul>
            </div>

            <div className="col-auto float-end ms-auto">

              <div className="dropdown">
                <button className="btn add-btn dropdown-toggle rounded-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="fa fa-plus"
                  />ADD
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li className="m-2">
                    <Link
                      to="#"
                      className="btn dropdown-item rounded-2"
                      onMouseOver={mouseOver} onMouseOut={mouseOut}
                      data-bs-toggle="modal"
                      data-bs-target={"#add_leavee"}
                    >
                      {/* <i className="fa fa-plus" /> */}
                      {"Leave"}
                    </Link>
                  </li>
                  <li className="m-2">
                    <Link
                      to="#"
                      className="btn dropdown-item rounded-2"
                      onMouseOver={mouseOver} onMouseOut={mouseOut}
                      data-bs-toggle="modal"
                      data-bs-target={"#add_wfh"}
                    >
                      {/* <i className="fa fa-plus" /> */}
                      {"WFH"}
                    </Link>
                  </li>
                  <li className="m-2">
                    <Link
                      to="#"
                      className="btn dropdown-item rounded-2"
                      onMouseOver={mouseOver} onMouseOut={mouseOut}
                      data-bs-toggle="modal"
                      data-bs-target={"#add_Visit"}
                    // onClick={}
                    >
                      {/* <i className="fa fa-plus" /> */}
                      {"Official Visit"}
                    </Link>
                  </li>
                </ul>
                <div className="view-icons">
                  <Link to="#" className="grid-view btn btn-link">
                    <i className="las la-redo-alt"  onClick={ApiCall}/>
                  </Link>
                  <Link
                    to="#"
                    className={
                      fieldInputs
                        ? "list-view btn btn-link active-filter"
                        : "list-view btn btn-link"
                    }
                    id="filter_search"
                    onClick={() => setFieldInputs(!fieldInputs)}
                  >
                    <i className="las la-filter" />
                  </Link>
                </div>
              </div>

            </div>

            <div
              className="filter-filelds"
              id="filter_inputs"
              style={{ display: fieldInputs ? "block" : "none" }}
            >
              <LeaveFilter setApptype={setApptype} setAppStatus={setAppStatus} setFromDate={setFromDate} setToDate={setToDate} fromDate={fromDate} toDate={toDate} />
            </div>
          </div>

          <div className="row">
            {leaveStats.map((stat, index) => (
              <div className="col-md-4" key={index}>
                <div className="stats-info">
                  <h6>{stat.title}</h6>
                  <h4>{stat.value}</h4>

                </div>
              </div>
            ))}
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">

                {/* {users && users?.length > 0}  */}
                {
                  <Table
                    rowKey={(record) => record.id}
                    scroll={{ y: 300 }}
                    // columns={columns}
                    columns={
                      isLoading
                        ? columns.map((col) => ({
                          ...col,
                          render: () => renderPlaceholder(), // Render placeholder for all columns
                        }))
                        : columns
                    }
                    dataSource={isLoading ? placeholderData : users?.length > 0 ? users : []}
                    pagination={{
                      currentPage: currentPage,
                      pageSize: pageSize,
                      total: totalRecord,
                      totalPage: totalPage,
                      className: "pointer",
                      onChange: (page, pageSize) => {
                        setCurrentPage(page);
                        setPageSise(pageSize);
                      },
                    }}

                    className="table-striped"
                  />
                }
                {/* <SearchBox /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <EmployeeLeaveModelPopup requestType="LEAVE" empData={empData} apptype={apptype} appStatus={appStatus} fromDate={fromDate} toDate={toDate}
        Remain_Leave={empStatsData.REM_LEAVE} />
      <EmployeeWFHModelPopup requestType="WFH" empData={empData} apptype={apptype} appStatus={appStatus} fromDate={fromDate} toDate={toDate} />
      <EmployeeVisitModelPopup requestType="OFFICIAL_TOUR" empData={empData} apptype={apptype} appStatus={appStatus} fromDate={fromDate} toDate={toDate} />

      {/* <EmpLeaveModal/> */}
      {/* <EmpWfhModal/> */}

      {/* Delete Modal */}
      {/* <DeleteModal Name="Delete Leaves" /> */}
      {/* Delete Modal */}
    </>
  );
};

export default EmployeeLeave;