import React, { useEffect, useState } from "react";
import Select from "react-select";
import {  toast } from "react-toastify";
import { getEmployeeDataByID, getEmployeeStatsData } from "../../Services/ApiCalling";


const ApplicationModal = ({ toastContent, reqState, setStatus, setRemarks, setId, handleSubmit, leave_status, setNoOfDays}) => {
  
  const [statdata, setStatData] = useState()
  const [noDays, setNoDays] = useState()
  // const [isLoading,setIsLoading] = useState(false)  //yash

  const token = localStorage.getItem("LogIn_Token")

  const statusOptions = [
    { value: "APPROVED", label: "Approved" },
    { value: "REJECT", label: "Rejected" },
  ];

  const getStatsForLeave = async (reqState) => {
    console.log("reqStatereqState",reqState)
    setNoDays(reqState?.NO_DAYS)
    if (reqState) {
      // setIsLoading(true)
      const stat = await getEmployeeStatsData(reqState?.EMP_ID)
      await setId(reqState?.ID)
      setStatData(stat.data)
      // setIsLoading(false)
    }
  }

  useEffect(() => {
    getStatsForLeave(reqState)
  }, [reqState])

  
  console.log("statdata", statdata)
  // console.log("reqState.ID", reqState.ID)

  // useEffect(() => {
  //   setId(reqState?.ID)
  // }, [reqState?.ID])

  // useEffect(() => {
  //   setNoOfDays(reqState?.NO_DAYS)
  // }, [reqState?.NO_DAYS])

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const renderPlaceholder = () => (
    <>
      <p className="placeholder-glow">
        <span className="placeholder col-12" />
      </p>
      <p className="placeholder-wave mb-0">
        <span className="placeholder col-12" />
      </p>
    </>
  );
  // console.log("statdata?.TOTAL_LEAVE statdata?.TOTAL_LEAVE", statdata?.TOTAL_LEAVE);
  const placeholderData = new Array(5).fill({});

  return (
    <div id="test" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Application Status</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
              <div style={{display:"flex", justifyContent:"space-between", marginBottom:"10px"}}>
                  <div style={{backgroundColor:"#e9ecef", padding:"15px"}}>
                      <h5> Total Leave</h5><h2>{statdata?.TOTAL_LEAVE}</h2>
                  </div>
                  <div style={{backgroundColor:"#e9ecef", padding:"15px"}}>
                  <h5>Remaining Leave</h5><h2>{statdata?.REM_LEAVE}</h2>
                  </div>
                  <div style={{backgroundColor:"#e9ecef", padding:"15px"}}>
                  <h5>Pending Leave</h5><h2>{statdata?.pendingRequestsCount}</h2>
                  </div>
              </div>
              <div style={{backgroundColor:"#e9ecef", padding:"10px", marginBottom:"10px"}}>
                 <span className="fs-6"> Applied for Days : </span><span className="fs-4 mx-3">{noDays}</span>
              </div>
            <form>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Status <span className="text-danger">*</span>
                </label>
                <Select
                  options={statusOptions}
                  placeholder="Select"
                  styles={customStyles}
                  onChange={(e) => {
                    // console.log("selectedddddd", e.value)
                    setStatus(e.value)
                  }}
                />
              </div>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Remarks <span className="text-danger">*</span>
                </label>
                <textarea
                  onChange={(e) => {
                    // console.log("remarkkkkk", e.target.value)
                    setRemarks(e.target.value)
                  }}
                  rows={4}
                  className="form-control"
                />
              </div>
              <div className="submit-section">
                <button
                  onClick={(reqState) => {
                    setId(reqState?.ID)
                    toast.error(toastContent);
                    handleSubmit()
                  }}
                  className="btn btn-primary submit-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="reset"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplicationModal;