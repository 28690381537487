/* eslint-disable no-unused-expressions */

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Row, Table } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import SearchBox from "../../../components/SearchBox";
import LeaveFilter from "../../../components/LeaveFilter";
import ApplicationModal from "./application";
import { LeaveDetails, LeaveStatus, TaskDetails } from "../../Services/ApiCalling";
import moment from 'moment';

const AdminLeave = ({setEmpName, from , to, setAppType, setAppStatus, setFrom,FROM,TO,STATUS,TYPE,empName,
   setTo, isLoading, leaveUsers, getLeaveData, emp_sts, currentPage, pageSize, totalRecord, totalPage, setCurrentPage, setPageSize}) => {
  const [fieldInputs, setFieldInputs] = useState(true);
  const [reqState, setReqState] = useState()
  const [isFullScreen, setFullScreen] = useState(false);
  const maximizeBtnRef = useRef(null);
  const [status, setStatus] = useState("Pending");
  const [remarks, setRemarks] = useState("");
  const [id, setId] = useState(null);
  const [noOfDays, setNoOfDays] = useState()
  const [toastContent, setToastContent] = useState();
  
  
  console.log("yks",reqState?.EMP_ID, reqState?.REQ_TYPE)
  const [taskData, setTaskData] = useState();

  useEffect(() => {
    const handleClick = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        setFullScreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          setFullScreen(false);
        }
      }
    };

    const cleanup = () => {
      if (isFullScreen && document.exitFullscreen) {
        document.exitFullscreen();
        setFullScreen(false);
      }
    };

    console.log("reqStatereqStatereqState", reqState)
    const maximizeBtn = maximizeBtnRef.current;
    maximizeBtn.addEventListener("click", handleClick);

    // Cleanup function to remove the event listener and exit fullscreen on component unmount
    return () => {
      maximizeBtn.removeEventListener("click", handleClick);
      cleanup();
    };
  }, [isFullScreen]);

  
  const token = localStorage.getItem("LogIn_Token")


  const getTasksData = async (token, reqState) => {
    console.log("kratiiiiiiiiii",reqState?.EMP_ID, reqState?.REQ_AT)
      const res = await TaskDetails(token, reqState?.EMP_ID, reqState?.REQ_AT)

      setTaskData(res.data)
      
      // const res2 = await getEmployeeStatsData(token)
      // setStatus(res2.data);
    }
   
    useEffect(()=>{
      if(reqState){
      getTasksData(token, reqState);
      }
    },[reqState])

  const ApiCall = ()=> {
    try{
      getLeaveData( token, "", "LEAVE", "PENDING", FROM, TO)
    }
    catch (error){
      console.error('Error:', error);
    }
  }
  const handleSubmit = async () => {
    console.log("rashi", id)
    const obj = {
      ID: id,
      STATUS: status,
      REMARK: remarks,
      NO_OF_DAYS: noOfDays
    };


    try {
     
      const response = await LeaveStatus(token, obj, reqState?.EMP_ID, reqState?.REQ_TYPE);
      console.log('Response://', response);
      setToastContent(response?.data?.Message)
      getLeaveData( token, empName, TYPE, STATUS, FROM, TO)
      
    } catch (error) {
      console.error('Error:', error);
    }
    console.log("LeaveUsers...", leaveUsers)
  };

  const columns = [

    {
      title: "Employee Id",
      dataIndex: "EMP_ID",
      render: (text, record) => {

        return (
          <span className="table-avatar" onClick={() => { console.log("record", record) }}>

            <Link to="/personProfile" className="avatar">
              <img className="avatar" alt="" src={record.image} />
            </Link>
            <span className="table-avatar">
              <Link to="/personProfile" className="avatar"></Link>
              {text}
            </span>
          </span>
        )
      },
      sorter: (a, b) => a.EMP_ID.length - b.EMP_ID.length,
    },

    {
      title: "Employee Name",
      dataIndex: "EMP_NAME",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.EMP_NAME.length - b.EMP_NAME.length,
    },

    {
      title: "Application Type",
      dataIndex: "REQ_TYPE",
      render: (text) => <span>{text}</span>,

      sorter: (a, b) => a.REQ_TYPE.length - b.REQ_TYPE.length,
    },

    {
      title: "From",
      dataIndex: "REQ_FROM",
      render: (text) => <span>{moment(text).format('DD-MM-YYYY')}</span>,
      sorter: (a, b) => new Date(a.REQ_FROM) - new Date(b.REQ_FROM),
    },
    {
      title: "To",
      dataIndex: "REQ_TO",
      render: (text) => <span>{moment(text).format('DD-MM-YYYY')}</span>,
      sorter: (a, b) => new Date(a.REQ_TO) - new Date(b.REQ_TO),
    },

    {
      title: "No Of Days",
      dataIndex: "NO_DAYS",
      render: (text) => <span>{text}</span>,

      sorter: (a, b) => a.NO_DAYS.length - b.NO_DAYS.length,
    },

    {
      title: "Reason",
      dataIndex: "REASON",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.REASON.length - b.REASON.length,
    },
    {
      title: "Status",
      dataIndex: "REQ_STATUS",
      // render: (text) => <span>{text}</span>,
      render: (text) => {
        let color;
        
        // Determine the color based on the status
        if (text === "PENDING") {
          color = "orange"; 
        } else if (text === "REJECT") {
          color = "red"; 
        } else {
          color = "green"; 
        }
        
        return <span style={{ color }}>{text}</span>;
      },
      sorter: (a, b) => a.REQ_STATUS.length - b.REQ_STATUS.length,
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <div className="dropdown dropdown-action text-end">
            <Link
              to="#"
              className="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons">more_vert</i>
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-backdrop="static"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
                // data-bs-target="#edit_leave"
                data-bs-target="#test"
                onClick={() => {
                  setReqState(record)
                  console.log("recor record record", record)
                }}
              >
{/* fa fa-pencil */}
                <i className=" m-r-5" /> &nbsp;
                 Status
              </Link>
            </div>
          </div>
        )
      },
    },
  ];

  const renderPlaceholder = () => (
    <>
      <p className="placeholder-glow">
        <span className="placeholder col-12" />
      </p>
      <p className="placeholder-wave mb-0">
        <span className="placeholder col-12" />
      </p>
    </>
  );

  const placeholderData = new Array(5).fill({});
  return (
    <>
      {/* <submitToast/> */}
      <ToastContainer />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-md-4">
                <h3 className="page-title">Leaves</h3>
                <ul className="breadcrumb">
                  {/* <li className="breadcrumb-item">
                    <Link to="">Dashboard</Link>            
                  </li> */}
                  <li className="breadcrumb-item active">Leaves</li>
                </ul>
              </div>
              <div className="col-md-8 float-end ms-auto">
                <div className="d-flex title-head">
                  <div className="view-icons">
                    <Link to="#" className="grid-view btn btn-link">
                      <i className="las la-redo-alt" onClick={ApiCall}/>
                    </Link>
                    <Link
                      to="#"
                      className="list-view btn btn-link"
                      id="collapse-header"
                      ref={maximizeBtnRef}
                    >
                      <i className="las la-expand-arrows-alt" />
                    </Link>
                    <Link
                      to="#"
                      
                      id="filter_search"
                      onClick={() => setFieldInputs(!fieldInputs)}
                      className={
                        fieldInputs
                          ? "list-view btn btn-link active-filter"
                          : "list-view btn btn-link"
                      }
                    >
                      <i className="las la-filter" />
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>
          {/* Page Header */}
          <hr />
          
          <div
            className="filter-filelds"
            id="filter_inputs"
            style={{ display: fieldInputs ? "block" : "none" }}
          >
            <LeaveFilter setEmpName={setEmpName} from = {from} to = {to} setAppType={setAppType} setAppStatus={setAppStatus} setFrom={setFrom} setTo={setTo}/>
          </div>
          {/* /Leave Statistics */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {
                  <Table
                    rowKey={(record) => record.id}
                    scroll={{ y: 300 }}
                    
                    columns={
                      isLoading
                        ? columns.map((col) => ({
                          ...col,
                          render: () => renderPlaceholder(), // Render placeholder for all columns
                        }))
                        : columns
                    }
                    dataSource={isLoading ? placeholderData : leaveUsers?.length > 0 ? leaveUsers : []}
                    pagination={{
                      currentPage: currentPage,
                      pageSize: pageSize,
                      total: totalRecord,
                      totalPage: totalPage,
                      className: "pointer",
                      onChange: (page)=>{
                        setCurrentPage(page);
                      },
                    }}
                    className="table-striped" 
                  /> 
                }
              </div>
            </div>
          </div>
        </div>

        {<ApplicationModal Name="Leave Status" toastContent={toastContent} reqState={reqState} setRemarks={setRemarks} setStatus={setStatus} setId={setId} handleSubmit={handleSubmit} leave_status={emp_sts} setNoOfDays={setNoOfDays} taskData={taskData}/>}
        {/* Delete Modal */}
        <DeleteModal Name="Delete Leaves" />
        {/* Delete Modal */}
      </div>
    </>
  );
};

export default AdminLeave;
