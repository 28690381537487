import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";

const LeaveFilter = ({setApptype, setAppStatus, setFromDate, setToDate, fromDate, toDate}) => {
  

  const [dateTwo, setdateTwo] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [itemFocus, setItemFocus] = useState(false);

  console.log( fromDate, toDate)

  const inputFocus = () => {
    setItemFocus(true);
  };

  const inputBlur = () => {
    setItemFocus(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocustwo = () => {
    setdateTwo(true);
  };
  const handleBlurtwo = () => {
    setdateTwo(false);
  };

  const handleDateChange = (fromDate) => {
    setFromDate(fromDate);
  };

  const handleDateChangeTwo = (toDate) => {
    setToDate(toDate);
  };
  const leave = [
    { value: "", label: "ALL" },
    { value: "LEAVE", label: "LEAVE" },
    { value: "WFH", label: "WFH" },
    { value: "OFFICIAL_TOUR", label: "OFFICIAL_TOUR" },
  ];
  const leavestatus = [
    { value: "", label: "ALL" },
    { value: "APPROVED", label: "APPROVED" },
    { value: "PENDING", label: "PENDING" },
    { value: "REJECT", label: "REJECT" },
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  return (
    <div>
      {/* Search Filter */}
      <div className="row filter-row">
        {/* <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
          <div
            className={`input-block mb-3 form-focus  ${
              itemFocus ? "focused" : ""
            } `}
          >
            <input
              type="text"
              className="form-control floating"
              onFocus={inputFocus}
              onBlur={inputBlur}
            />
            <label className="focus-label">Employee Name</label>
          </div>
        </div> */}
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
          <div className="input-block mb-3 form-focus select-focus">
            <Select
              options={leave}
              onChange={setApptype}
              // defaultInputValue="LEAVE"
              placeholder="LEAVE"
              styles={customStyles}
              onKeyDown={(e) => {
                e.preventDefault();
            }}
            />
            <label className="focus-label">Application Type</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
          <div className="input-block mb-3 form-focus select-focus">
            <Select
              options={leavestatus}
              onChange={setAppStatus}
              // value="PENDING"
              placeholder="PENDING"
              styles={customStyles}
              onKeyDown={(e) => {
                e.preventDefault();
            }}
            />
            <label className="focus-label">Application Status</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
          <div
            className={`input-block mb-3 form-focus ${
              isFocused ? "focused" : ""
            }`}
          >
            <div className="cal-icon focused ">
              <DatePicker
                className="form-control floating datetimepicker"
                selected={fromDate}
                onChange={handleDateChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                dateFormat="dd-MM-yyyy"
                onKeyDown={(e) => {
                  e.preventDefault();
              }}
              />
            </div>
            <label className="focus-label">From</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
          <div
            className={`input-block mb-3 form-focus ${
              dateTwo ? "focused" : ""
            }`}
          >
            <div className="cal-icon">
              <DatePicker
                className="form-control floating datetimepicker "
                selected={toDate}
                onChange={handleDateChangeTwo}
                onFocus={handleFocustwo}
                onBlur={handleBlurtwo}
                dateFormat="dd-MM-yyyy"
                onKeyDown={(e) => {
                  e.preventDefault();
              }}
              />
            </div>
            <label className="focus-label">To</label>
          </div>
        </div>
        {/* <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <Link to="#" className="btn btn-success w-100">
            search
          </Link>
        </div> */}
      </div>
      {/* /Search Filter */}
    </div>
  );
};

export default LeaveFilter;
